<template>
  <div class="account-wrap">
    <v-btn color="white" fab top left small @click="back">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <div class="inner-container">
      <div>
        <div class="account-info">
          <div class="container grid-list-xl">
            <div class="layout justify-start mt-0 mb-4 mx-0">
              <!-- <div class="px-4">
                <h4 v-if="getUserInfo != undefined">
                  {{ $t("message.hi") }}
                  {{
                    getUserInfo["firstname"] + " " + getUserInfo["lastname"]
                  }},
                </h4>
              </div> -->
            </div>
            <v-layout row wrap fill-height profile-list>
              <v-flex xs12 sm12 md4 lg3 xl2 user-nav-list px-0>
                <div class="emb-card account-block fill-height py-4">
                  <template v-for="(option, key, index) in settings">
                    <v-list
                      v-for="cateogary in settings[key]"
                      :key="cateogary.name"
                      class="py-0 text-center"
                    >
                      <v-list-item
                        @click="onTabChange(index)"
                        :to="cateogary.path"
                      >
                        <v-list-item-action class="ma-0">
                          <v-icon>{{ cateogary.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="py-0 text-left">
                          <v-list-item-title>
                            <span>{{ cateogary.title }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </div>
              </v-flex>
              <v-flex xs12 sm12 md8 lg9 xl9 user-content-wrapper>
                <div v-for="(option, key, index) in settings" :key="key">
                  <router-view v-if="index == selectedTab"></router-view>
                </div>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      customer_id: "",
      settings: {
        customerProfile: [
          {
            title: this.$t("message.profilePage.profile"),
            icon: "account_circle",
            path: "/admin-panel/customer-profile",
            id: "customerProfile",
          },
        ],
        customerCart: [
          {
            title: this.$t("message.profilePage.cart"),
            icon: "mdi-cart",
            path: "/admin-panel/customer-cart-view",
            id: "customerCart",
          },
        ],
        customerOrderHistory: [
          {
            title: this.$t("message.profilePage.orderHistory"),
            icon: "history",
            path: "/admin-panel/customer-order-history",
            id: "customerOrderHistory",
          },
        ],
        customerHistory: [
          {
            title: this.$t("message.profilePage.userHistory"),
            icon: "history",
            path: "/admin-panel/customer-history",
            id: "customerHistory",
          },
        ],
        customerAction: [
          {
            title: this.$t("message.profilePage.actions"),
            icon: "mdi-ticket",
            path: "/admin-panel/customer-action",
            id: "customerAction",
          },
        ],
        // cards: [
        //   {
        //     title: "Saved Cards",
        //     icon: "credit_card",
        //     path: "/account/cards",
        //     id: "cards",
        //   },
        // ],
      },
      selectedTab: 0,
    };
  },
  mounted() {
    this.customer_id = this.$route.params.id;
    this.settings.customerOrderHistory[0].path =
      "/admin-panel/customer-order-history/" + this.customer_id;
    this.settings.customerHistory[0].path =
      "/admin-panel/customer-history/" + this.customer_id;
    this.settings.customerProfile[0].path =
      "/admin-panel/customer-profile/" + this.customer_id;
    this.settings.customerAction[0].path =
      "/admin-panel/customer-action/" + this.customer_id;
    this.settings.customerCart[0].path =
      "/admin-panel/customer-cart-view/" + this.customer_id;
  },
  methods: {
    onTabChange(key) {
      this.selectedTab = key;
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
